import { Fragment } from 'react';
import Scrollbar from 'react-perfect-scrollbar';

import { Navigation } from 'app/components';
import { navigations } from 'app/navigations';
import { useDispatch } from 'react-redux';
import { setMode } from 'app/redux/actions/SettingActions';
import { isMobile } from 'app/utils/utils';
import Icon from 'app/components/Icon';

const Sidenav = ({ children, hover }) => {
  //const settings = useSelector(store=>store.settings);
  const dispatch = useDispatch();
  
  return (
    <Fragment>
      <Scrollbar options={{ suppressScrollX: true }} sx={{ position: 'relative' }}>
        {children}
        <Navigation items={navigations} hover={hover} onClick={()=>{ 
          if( isMobile() ) dispatch(setMode('close'))
        }} />
      </Scrollbar>
      <div className="sidenave_mobile" onClick={()=>dispatch(setMode('close'))}>
        <div className="cls"><Icon className="icon">times:r</Icon></div>
      </div>
    </Fragment>
  );
};

export default Sidenav;