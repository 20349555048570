import { CircularProgress } from '@mui/material';

const Loading = () => {
  return (
    <div className="box-loading">
      <div>
        <img src="/favicon.ico" alt="" />
        <CircularProgress className="circleProgress" />
      </div>
    </div>
  );
};

export default Loading;
