import Api from 'app/utils/Api';

export default function update(state = null){
    return async dispatch => {
    	if( state ){
    		return dispatch({
	            type: 'UPDATE_COUNTER',
	            payload: state
	        });
	    }
	    const response = await Api.get('user/counts');
	    if( response.success && response.data ){
	        return dispatch({
	            type: 'UPDATE_COUNTER',
	            payload: response.data
	        });
	    }
	    if( !response.success && (response?.code === 401 || response?.code === 403) ){
	    	localStorage.removeItem('userData');
	    	if( response?.code === 401 )
		    	localStorage.removeItem('accessToken');
	    	return dispatch({
	    		type: 'LOGOUT'
	    	});
	    }
	}
}

export const reset = async() => {
	return async dispatch => dispatch({ type: 'RESET_COUNTER' }); 
};

export const setRunning = async (payload) => {
	return async dispatch => dispatch({ type: 'RUNNING', payload }); 
};