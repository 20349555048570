import { useEffect, useState } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { init,setGeoData } from 'app/redux/actions/AuthActions';
import ToastContainer from 'app/components/ToastContainer';
import { getGeoLocation } from 'app/services/geolocation';
import { useLocation } from 'react-router-dom';
import Api from 'app/utils/Api';
//import Timer from 'app/components/Timer';
//import CounterActions from 'app/redux/actions/CounterActions';
//import interval from '../../../config/interval';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import '../../../styles/common.scss';

const Theme = ({ routes }) => {

    const [pageRoutes,setRoutes] = useState(routes);
    const content = useRoutes(pageRoutes);

    const auth = useSelector(store=>store.auth);
    const settings = useSelector(store=>store.settings);

    const dispatch = useDispatch();
    const location = useLocation();
    const [geoLoaded,setGeoLoad] = useState(false);

    useEffect(()=>{
        getInt();
    },[]);
    
    useEffect(()=>{
        if( auth.isLogged )
            setRoutes(parseRoutes(routes,auth?.user?.user_type || "G"));
    },[auth.isLogged]);

    useEffect(()=>{
        if( auth.isLogged && auth.geoData?.latitude )
            Api.post('user/update/geo/2',auth.geoData).then(res=>{ });
    },[auth.isLogged,auth.geoData]);
    
    useEffect(()=>{
        if( !geoLoaded && (auth.isLogged || location?.pathname === '/register') ){
            getGeoLocation(pos=>dispatch(setGeoData(pos)),{enableHighAccuracy: true});
            setGeoLoad(true);
        }
    },[auth.isLogged,location?.pathname]);

    //useEffect(()=>getCounters(1000),[auth?.isLogged]);

    const parseRoutes = (_routes,authType) => {
        var list = [];
        _routes.map(route=>{

            var allow = true, _auth = route?.auth;
            if( _auth )
                allow = _auth.includes(authType);

            if( allow ){
                
                if( route?.children ){
                    var _children = [];
                    route.children.map(_route=>{

                        var _allow = true, __auth = _route?.auth;
                        if( __auth )
                            _allow = __auth.includes(authType);
            
                        if( _allow )
                            _children.push(_route);

                        return _route;
                    }); 

                    if( _children.length ){
                        route.children = _children;
                        list.push(route);
                    }
                } else
                    list.push(route);    
            }
            return route;
        });
        
        return list;
    }

    const getInt = () => {
        dispatch(init());
    }
    /*
    const getCounters = (t) => {
        if( auth?.isLogged )
            setTimeout(()=>dispatch(CounterActions()),t);  
    }

    const onInterval = (s) =>getCounters(0);
    */

    let activeTheme = { ...settings.themes[settings.activeTheme] };
    // {auth?.isLogged && <Timer time={interval?.counter || 6} onInterval={onInterval}/>}
            
    return (
        <ThemeProvider theme={activeTheme}>
            <CssBaseline />
            {content}
            <ToastContainer />
        </ThemeProvider>
    );
};

export default Theme;
