
export const add = (id,content) => (dispatch) => {
    dispatch({
        type: 'ADD_TOAST',
        payload: { id, content },
    });
};

export const remove = (id) => (dispatch) => {
    dispatch({
        type: 'REMOVE_TOAST',
        payload: id,
    });
};

export const removeAll = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TOASTS',
    });
};