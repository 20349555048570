import React from 'react';

export default class Timer extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            seconds: 0,
        };
        this.timer = null;
    }
    componentDidMount() {
        this.setState({seconds:0});
        if( this.timer )
            clearInterval(this.timer);
        if( this.props?.onInterval )
            this.start();
    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }

    start() {
        var self = this;
        this.timer = setInterval(() =>{
            self.props?.onInterval(self.state.seconds);
            self.setState({seconds:self.state.seconds+1});
        }, (this.props?.time || 1) * 1000);
    }

    render() {
        return null;
    }
}