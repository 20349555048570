import axios from "axios";

class Api{

    config  = {};
    headers = {};
    accessToken = null;
    viewLogs = false;
    
    constructor() {
        this.config = {
            apiUrl: process.env?.REACT_APP_API_URL || '/',
            apiKey: process.env?.REACT_APP_API_KEY
        };

        this.headers = {
            'api-key': this.config.apiKey,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Max-Age': 600,
        };
        this.setToken(null,true);
    } 

    setToken = (token = null) =>{
        this.accessToken = token;
        if( this.viewLogs )
            console.log("Access Token: "+this.accessToken);
    }

    getToken = () => this.accessToken;

    prepareUrl = (path) =>{
        path = (path || '').trim();

        if( path.indexOf('://') !== -1 )
            return path;
        
        return this.config.apiUrl.trim()+path;
    }

    prepareParams = (method, path, conf = {}) =>{

        if( conf?.accessToken || this.accessToken )
            this.headers['Authorization'] = 'Bearer '+(conf?.accessToken || this.accessToken).toString();

        let params = {
            method: method,
            headers: this.headers,
        };

        params['url'] =this.prepareUrl(path);

        //if( conf?.upload )
          //  delete conf['upload'];

        if( conf ){
            Object.keys(conf).forEach(key => {
                params[key] = conf[key];       
            });
        }

        return params;
    }

    get = async(path,conf = {}) => {
        return await this.call('get',path, null, conf);
    }

    post = async(path,data,conf = {}) => {
        return await this.call('post',path, data, conf);
    }

    put = async(path,data,conf = {}) => {
        return await this.call('put',path, data, conf);
    }

    delete = async(path,conf = {}) => {
        return await this.call('delete',path, null, conf);
    }

    upload = async(path, name = null, files = null, data ={}, conf = {}) => {
        
        var multi = conf?.multiple || (typeof files === 'array' ? true : false);
        if( conf?.multiple) delete conf.multiple;
       
        var ret = {}, params = this.prepareParams('POST', path, conf);
        
        if( !name && !files )
            ret = { success:false, message: "Files are required to upload", data:{}, code: 0 };

        try{ 

            var _config = { headers: { ...params.headers, "Content-Type": "multipart/form-data"}, ...conf};
            var _nm = '', fdata = new FormData();

            if( name && Array.isArray(files) ){
                _nm = name || "file";
                if( multi ) _nm = `${_nm}[]`;
                files.map(file=>fdata.append(_nm,file));
                
            } else if( typeof files === 'object' ){
                Object.entries(files).map(([fk,fs])=>{
                    if( typeof fs === 'object')
                        fs = Object.values(fs);
                    if( fs) {
                        _nm = fk;
                        if( multi[fk] ) _nm = `${_nm}[]`;
                        fs.map(file=>fdata.append(_nm,file));
                    }
                    return fs;
                });
            }

            Object.entries(data || {}).map(([fk,fv])=>{
                fdata.append(fk.toString(),typeof fv === 'string' || fv instanceof String ? fv.toString() : JSON.stringify(fv));
                return fv;
            });

            var resp = await axios.post(params['url'], fdata, _config);
            ret = resp.data;
            if( !ret.success && !ret.message )
                ret['message'] = "Unable to process request";

            if( resp?.code >= 400 && resp?.code !== 402 ){
                ret.success = false;
                ret.message = "Opps, Something went wrong, try again";
            }

            if( this.viewLogs ){
                console.log(ret);
                console.log("API CALL: END ==========================");
            }

        } catch (err) {
            ret = { success:false, message: err.message || "Unable to process request", data:{}, code: 0 };
        }
        
        return ret;
    }

    call = async(method,path,data ={},conf = {}) => {

        var _frmt = (conf && conf?.external) || false; 
        if( conf && conf?.external) delete conf.external;
        
        var ret = {}, params = this.prepareParams(method, path, conf);
    
        try{ 
            
            if( data ) 
                params['data'] = data;

            if( this.viewLogs ){
                console.log("API CALL: "+method+" /"+path+": Start ==========================");
                console.log(data);
            }
            
            await axios(params).then((resp) => {        
                if( _frmt )
                    ret = { success:true, message:null, data:resp, code: 200 };
                else {
                    ret = resp.data;
                    if( !ret.success && !ret.message )
                        ret['message'] = "Unable to process request";

                    if( resp?.code >= 400 ){
                        ret.success = false;
                        ret.message = "Opps, Something went wrong, try again";
                    }
                }
            }).catch((error) => {
                ret = { success:false, message: error.response?.status === 0 ? error.message : "Opps, Something went wrong, try again", data:{}, code: error.response?.status };
            });
            if( this.viewLogs ){
                console.log(ret);
                console.log("API CALL: END ==========================");
            }
        } catch (err) {
            ret = { success:false, message:"Unable to process request", data:{}, code: 0 };
        }
        
        return ret;
    }
}

export default new Api();