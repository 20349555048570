const pageMeta = {
    '/login': { title: "Login" },
    '/register': { title: "Register" },
    '/forgotpassword': { title: "Reset Password" },
    '/users': { title: "Users" },
    '/blogs/*': { title: "Blogs" },
    '/places': { title: "Places near you" },
    '/news/*': { title: "News" },
    '/about.html': { title: "About" },
    '/privacy-policy.html': { title: "Privacy Policy" },
    '/terms-conditions.html': { title: "Terms Conditions" },
    '/contact-us.html': { title: "Contact Us" },
};

export default pageMeta;