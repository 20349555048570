import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Profile = Loadable(lazy(() => import('./Profile')));
const NotFound = Loadable(lazy(() => import('./NotFound')));

const routes = [
    { path: '/user/:username', element: <Profile /> },
    { path: '/user/:username/:page', element: <Profile /> },
    { path: '/user/:username/:page/:id', element: <Profile /> },
    { path: '/user/:username/:page/id/:id', element: <Profile /> },
    { path: '/user', element: <NotFound /> }    
];

export default routes;
