import { themes } from '../Theme/initThemes';
import layout1Settings from './Layout1/Layout1Settings';

// UPDATE BELOW CODE
export const LayoutSettings = {
  activeLayout: 'layout1', // layout1, layout2
  activeTheme: 'main', // View all valid theme colors inside Theme/themeColors.js
  perfectScrollbar: false,

  themes: themes,
  layout1Settings, // open Layout1/Layout1Settings.js

  secondarySidebar: {
    show: true,
    open: false,
    theme: 'leftbar', // View all valid theme colors inside Theme/themeColors.js
  },
  // Footer options
  footer: {
    show: false,
    fixed: false,
    theme: 'leftbar', // View all valid theme colors inside Theme/themeColors.js
  },
};
