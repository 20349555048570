
const initialState = {
    open: false,
    items: {}
}

const ToastReducer = function (state = initialState, action) {
    switch (action.type) {
        case "ADD_TOAST" : {
            let items = state.items, { id, content } = action.payload;
            if( id && content )
                items[id] = content;
            return {...state, items, open:true};
        }

        case "REMOVE_TOAST" : {
            let open = state.open, items = state.items, id = action.payload;
            if( items[id] )
                delete items[id];
            if( Object.keys(items).length <= 0)
                open = false;
            return {...state, items, open};
        }
        case "CLEAR_TOASTS" : {
            return {...state, items: {}, open: false};
        }
        
        default: 
            return {...state};
    }
};

export default ToastReducer;
