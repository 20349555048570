import { Box, Container } from '@mui/system';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const NotFound = ({ type = 'Page', msg = null, container = true, className = null}) => {

  const content = () => <Box className={`page-content page-not-found ${className || ''}`}>
      <SentimentVeryDissatisfiedIcon/>
      {!msg && <h1>404</h1>}
      <h4>{msg || `Oops! ${type} not found`}</h4>
      <p>{!msg ? `The ${type} you have requested could not be found.` : <br/>}</p>
    </Box>;

  return container ? <Container>{content()}</Container> : content();
};

export default NotFound;