const Layout1Settings = {
  leftSidebar: {
    show: true,
    mode: 'compact', // full, close, compact, mobile,
    theme: 'leftbar', // View all valid theme colors inside Theme/themeColors.js
  //  bgImgURL: '/assets/images/sidebar/sidebar-bg-dark.jpg',
  },
  topbar: {
    show: true,
    fixed: true,
    theme: 'topbar', // View all valid theme colors inside Theme/themeColors.js
  },
};

export default Layout1Settings;
