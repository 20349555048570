const initialState = {
    isInitialised: false,
    isLogged: false,
    accessToken: null,
    user: {},
    error: false,
    firebaseToken: null,
    blockedUsers: [],
    geoData: {},
};

const AuthReducer = function (state = initialState, action) {
    var pyd;
    switch (action.type) {

        case 'INIT': {
            return {...state, isInitialised: true, ...action.payload};
        }

        case 'LOGIN': {
            pyd = action?.payload;
            if( pyd?.blockedUsers ) pyd.blockedUsers = [];
            return {
                ...state,
                isInitialised: true,
                isLogged: true,
                ...pyd
            }
        }

        case 'REGISTER': {
            pyd = action?.payload;
            if( pyd?.blockedUsers ) pyd.blockedUsers = [];
            return {
                ...state,
                isInitialised: true,
                isLogged: true,
                ...pyd
            }
        }

        case 'SET_FIREBASE_TOKEN': {
            return {
                ...state,
                firebaseToken: action.payload
            }
        }

        case 'SET_GEO_DATA': {
            if( action.payload?.latitude && action.payload?.longitude )
                 state.user = { ...state.user, ...{ map_lat: action.payload?.latitude, map_lng: action.payload?.longitude }};
            return {
                ...state,
                geoData: action.payload
            }
        }
    
        case 'BLOCK_USER': {
           return {
                ...state,
                blockedUsers: Array.from(new Set([...state?.blockedUsers || [], ...[action?.payload || 0]]))
            }
        }

        case 'UNBLOCK_USER': {
            return {
                ...state,
                blockedUsers: (state?.blockedUsers || []).filter(bu=>bu !==(action?.payload || 0)),
            }
        }

        case 'UPDATE_USER': {
            if( action.payload )
                localStorage.setItem('userData', JSON.stringify({...state.user, ...action.payload}));
            return {
                ...state,
                user: {...state.user, ...action.payload},
            }
        }

        case 'UPDATE_PROFILE': {
            var prf = state?.user?.profile || {};
            prf = {...prf, ...action.payload};
            if( action.payload )
                localStorage.setItem('userData', JSON.stringify({...state.user, profile: prf }));
            return {
                ...state,
                user: {...state.user, profile: prf },
            }
        }

        case 'LOGOUT': {
            return {
                ...state,
               // isInitialised: false,
                isLogged: false,
                accessToken: null,
                user: {},
                blockedUsers: [],
                error: false,
            }
        }

        default: {
            return { ...state }
        }
    }
};

export default AuthReducer;