import { Navigate } from 'react-router-dom';

import AuthGuard from 'app/auth/AuthGuard';
import Layout from './components/Layout/Layout';

import dashboardRoutes from 'app/views/dashboard/routes';
import defaultRoutes from 'app/views/default/routes';
import profileRoutes from 'app/views/profile/routes';
import NotFound from 'app/views/default/NotFound';

const routes = [
  {
    element: (
      <AuthGuard>
        <Layout type="dashboard"/>
      </AuthGuard>
    ),
    children: [...dashboardRoutes],
  },
  {
    element: <Layout type="profile" />,
    children: [...profileRoutes],
  },
  {
    element: (
      <Layout type="default" />
    ),
    children: [...defaultRoutes],
  },
  { path: '/', element: <Navigate to="/" /> },
  { path: '*', element: <Layout type="default" component={<NotFound/>} /> },
];

export default routes;
