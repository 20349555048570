const initialState = {
    requests: 0,
    friends: 0,
    pokes: 0,
    messages: 0,
    notifications: 0,
    visitors: 0,
    running: true,
};

const CounterReducer = function (state = initialState, action) {

    switch (action?.type) {

        case 'RUNNING':
            return {...state,  running:action.payload };

        case 'UPDATE_COUNTER':
            return {...state,  ...action.payload };
        
        case 'RESET_COUNTER':
            return {...state,  ...initialState };

        default:
            return state;
    }
};
  
export default CounterReducer;