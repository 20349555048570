import { Provider } from 'react-redux';
//import { useRoutes } from 'react-router-dom';
import { Theme } from './components';
import { Store } from './redux/Store';
import routes from './routes';

const App = () => {
  //const content = useRoutes(routes);
  return (
    <Provider store={Store}>
      <Theme routes={routes}/>
    </Provider>
  );
};

export default App;
