import { Box, ButtonBase, styled } from '@mui/material';
import React, { Fragment } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Paragraph, Span } from '../Typography';
import ExpansionPanel from './ExpansionPanel';
import { useSelector } from 'react-redux';
import Icon from 'app/components/Icon';

const NavigationBox = styled('div')(({ theme, hover }) => ({
  '& .compactNavItem': {
   // '&.lnmenu-0': { marginTop: hover ? 0 : "12px" },
    overflow: 'hidden',
    justifyContent: 'center !important',
    height: hover ? "36px" : "42px",
    '& .icon':{ marginLeft:hover ? "16px" : "20px", fontSize: hover ? "20px" : "22px" ,width:"28px", height:"23px", marginTop:"2px", },
    '& .bgv': { top:"2px" },
  },
}));

const ListLabel = styled(Paragraph)(({ theme, mode }) => ({
  fontSize: '12px',
  marginTop: '20px',
  marginLeft: '15px',
  marginBottom: '10px',
  textTransform: 'uppercase',
  display: mode === 'compact' && 'none',
  color: theme.palette.text.secondary,
}));

const ExtAndIntCommon = {
  display: 'flex',
  overflow: 'hidden',
 // borderRadius: '4px',
  height: 36,
  borderRight:"4px solid transparent",
  whiteSpace: 'pre',
  marginBottom: '1px',
  textDecoration: 'none',
  justifyContent: 'space-between',
  transition: 'all 200ms ease-in',
  '&:hover': { background: '#e5e5e5' },
  '& .icon': {
    fontSize: '16px',
    width:"20px",
    textAlign:"center",
    marginLeft: '20px',
    marginRight: '0px',
    verticalAlign: 'middle',
  },
};
const ExternalLink = styled('a')(({ theme }) => ({
  ...ExtAndIntCommon,
  color: theme.palette.text.primary,
}));

const InternalLink = styled(Box)(({ theme }) => ({
  '& a': {
    ...ExtAndIntCommon,
   // color: theme.palette.text.primary,
  },
  '& .navItemActive': {
    borderRightColor:"#33B0F2",
    backgroundColor: '#e5e5e5',//rgba(255, 255, 255, 0.25)',
    color:"#333"
  },
}));

const StyledText = styled(Span)(({ mode }) => ({
  fontSize: '0.875rem',
  paddingLeft: '0.6rem',
  display: mode === 'compact' && 'none',
  whiteSpace:"nowrap",
}));

const BulletIcon = styled('div')(({ theme }) => ({
  padding: '2px',
  marginLeft: '24px',
  marginRight: '8px',
  overflow: 'hidden',
  borderRadius: '300px',
  background: theme.palette.text.primary,
}));

const BadgeValue = styled('div')(() => ({
  padding: 0,
  overflow: 'hidden',
  borderRadius: '50%',
  background: '#d00',
  border: '0',
  display: 'inline-block',
  width: '20px',
  height: '20px',
  fontSize: '12px',
  lineHeight: '20px',
  textAlign: 'center',
  boxShadow: '0px 0px 0px #000',
  right: '10px',
  letterSpacing: '-1px',
  position:"absolute",
  color:"#FFF",
}));

const Navigation = ({ items,hover, onClick }) => {
  
  const user = useSelector(store=>store.auth.user);
  const settings = useSelector(store=>store.settings);
  const counter = useSelector(store=>store.counter);
  const { mode } = settings.layout1Settings.leftSidebar;
  const location = useLocation();
  const navigate = useNavigate();
    
  const clickLink = (item, path, e)=>{
    if( (item.path || '').toString().includes('[') ){
        e.preventDefault();
        navigate(path,{state: user});
        return false;
    }
    if( onClick ) onClick(item);
  }

  const renderLevels = (data) => {
    return data.map((item, index) => {
        
      var show = true;
      if( item?.auth && user?.user_type )
        show = item.auth.includes(user?.user_type);

      if( !show )
        return null;  

      let badge = item?.badge && counter[item?.badge] ? counter[item?.badge] : 0;
      if (item.type === 'label')
        return (
          <ListLabel key={index} mode={mode} className="sidenavHoverShow">
            {item.label}
          </ListLabel>
        );

      if (item.children) {
        return (
          <ExpansionPanel mode={mode} item={item} key={index} css={ExtAndIntCommon}>
            {renderLevels(item.children)}
          </ExpansionPanel>
        );
      } else if (item.type === 'extLink') {
        return (
          <ExternalLink
            key={index}
            href={item.path}
            className={`${mode === 'compact' && 'compactNavItem'}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <ButtonBase key={item.name} name="child" sx={{ width: '100%' }}>
              {(() => {
                if (item.icon) {
                  return <Icon className="icon">{item.icon}</Icon>;
                } else {
                  return <span className="item-icon icon-text">{item.iconText}</span>;
                }
              })()}
              <StyledText mode={mode} className="sidenavHoverShow">
                {item.name}
              </StyledText>
              <Box mx="auto"></Box>
              {badge > 0 && <BadgeValue className='bgv'>{badge > 9 ? '9+': badge}</BadgeValue>}
            </ButtonBase>
          </ExternalLink>
        );
      } else {

        var path = (item.path || '').toString().replace('[username]',user?.username || '');
        return (
          <InternalLink key={`lnm-${index}`}>
            <NavLink
              to={path}
              onClick={(e)=>clickLink(item,path,e)}
              className={(props) =>{
                var cls = `lnmenu-${index}`;
                if( mode === 'compact' )
                    cls = `${cls} compactNavItem`;
                if( location.pathname.toString() === path )
                    cls = `${cls} navItemActive`;
                return cls;
              }}
            >
              <ButtonBase key={item.name} name="child" sx={{ width: '100%' }}>
                {item?.icon ? (
                  <Icon className="icon" sx={{ width: 36 }}>
                    {item.icon}
                  </Icon>
                ) : (
                  <Fragment>
                    <BulletIcon
                      className={`nav-bullet`}
                      sx={{ display: mode === 'compact' && 'none' }}
                    />
                    <Box
                      className="nav-bullet-text"
                      sx={{
                        ml: '20px',
                        fontSize: '11px',
                        display: mode !== 'compact' && 'none',
                      }}
                    >
                      {item.iconText}
                    </Box>
                  </Fragment>
                )}
                <StyledText mode={mode} className="sidenavHoverShow">
                  {item.name}
                </StyledText>

                <Box mx="auto" />

                {badge > 0 && (
                  <BadgeValue className="sidenavHoverShow bgv">{badge > 9 ? '9+' : badge}</BadgeValue>
                )}
              </ButtonBase>
            </NavLink>
          </InternalLink>
        );
      }
    });
  };

  return <NavigationBox hover={hover ? 1 : 0}>{renderLevels(items)}</NavigationBox>;
};

export default React.memo(Navigation);
