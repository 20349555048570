import { memo } from 'react'
import { styled, Icon as MuiIcon } from '@mui/material';

const IconBoxMTR = styled(MuiIcon)(({ color, size = 0  }) => ({
    fontSize: size > 0 ? `${size}px !important` : 'inherit',
    color: color,
}));

const IconBoxAWS = styled('i')(({ color, size = 0  }) => ({
    fontSize: size > 0 ? `${size}px !important` : null,
    color: color,
}));

const Icon = (props) => {
    
    if( !(props?.children || props?.name) )
        return null;

    let source = props?.source || "awesome";
    if( !['awesome','material'].includes(source) )
        return null;

    let _prps = {}, 
        _name = (props?.children || (props?.name || '')).toString().trim(),
        color = props?.color || null,
        size = (props?.size || '').toString().replace('px',''), 
        type = props?.type || '';
        
    if( size === '') size = 0;
    size = parseInt(size);

    if( source === 'material')
        return <IconBoxMTR color={color} size={size} {...props}>{_name}</IconBoxMTR>;
    
    if( _name.includes(':') ){
        var _nmp = _name.split(':');
        _name = _nmp[0].trim();
        type = _nmp[1] || '';
    }

    var cls = `fa${type.trim()} fa-${_name}`;
    if( props?.wide )     cls = `${cls} fa-fw`;
    if( props.class )     cls = `${cls} ${props.class}`;
    if( props.className ) cls = `${cls} ${props.className}`;
    if( size > 0 )        _prps.size = size;
    if( color )           _prps.color= color;
    if( props?.style )    _prps.style = props.style;
    if( props?.sx )       _prps.sx = props.sx;
    if( props?.onClick )  _prps.onClick = props.onClick;
    
    return <IconBoxAWS {..._prps} className={cls}/>;
}

export default memo(Icon);
