
export const setMode = (mode) =>{    
    return function(dispatch) {
        return dispatch({
            type: 'SET_MODE',
            payload: mode
        });
    }
}

export const updateSettings = (settings) =>{    
    return function(dispatch) {
        return dispatch({
            type: 'UPDATE_SETTINGS',
            payload: settings
        });
    }
}