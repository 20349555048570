import jwtDecode from 'jwt-decode';
import Api from 'app/utils/Api';

const isValidToken = (accessToken) => {
    if (!accessToken) return false;
    const decodedToken = jwtDecode(accessToken)
    return decodedToken?.ID ?true : false;
}

const setSession = (accessToken, data = null) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        Api.setToken(accessToken);
        if( data )
            localStorage.setItem('userData', JSON.stringify(data));
    } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userData');
        Api.setToken(null);
    }
}

export const init = () => {

    return async dispatch => {
        const accessToken = await window.localStorage.getItem('accessToken');
        if( accessToken && isValidToken(accessToken) ){
            setSession(accessToken);
            
            let userData = await window.localStorage.getItem('userData');
            if( userData && userData.includes('{') ) 
                userData = JSON.parse(userData);
    
            if( userData?.ID ){
                dispatch({
                    type: 'INIT',
                    payload: {
                        isLogged: true,
                        user: userData,
                        accessToken: accessToken,
                    }
                });
            } /*
            else {
                dispatch({
                    type: 'INIT',
                    payload: {
                        isLogged: false,
                        user: {},
                    }
                })
            }
            */
            const response = await Api.get('auth/verify');
            if( response.success ){
               // localStorage.setItem('userData', JSON.stringify(response.data));
                return dispatch({
                    type: 'INIT',
                    payload: {
                        isLogged: true,
                        user: response.data,
                        accessToken: accessToken,
                    }
                });
            } else {
                if( response?.code === 401 || response?.code === 403 ){
                    localStorage.removeItem('userData'); 
                    if( response?.code === 401 )
                        setSession(null);
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isLogged: false,
                            user: {},
                        }
                    });
                }
            }
            
        } else {
            setSession(null);
            return dispatch({
                type: 'INIT',
                payload: {
                    isLogged: false,
                    user: {},
                }
            });
        }
    }
}

export const login = (email, password) => {
    return async dispatch => {
        const response = await Api.post('auth/login', { email, password });
        if( response.success ){
            const { accessToken, user } = response.data;
            setSession(accessToken,user);
            Api.setToken(accessToken);
            dispatch({
                type: 'LOGIN',
                payload: {
                    user,
                    accessToken
                },
            });
        }
        return response;
    }
}

export const blockUser = (id) => {
    return async dispatch => dispatch({ type: 'BLOCK_USER', payload: id});
}

export const unblockUser = (id) => {
    return async dispatch => dispatch({ type: 'UNBLOCK_USER', payload: id});
}

export const updateUser = (data) => {
    return async dispatch => dispatch({ type: 'UPDATE_USER', payload: data });
}

export const updateProfile = (data) => {
    return async dispatch => dispatch({ type: 'UPDATE_PROFILE', payload: data });
}

export const setAuth = (user, accessToken) => {
    return async dispatch => {
        setSession(accessToken);
        Api.setToken(accessToken);
        dispatch({
            type: 'LOGIN',
            payload: {
                user,
                accessToken
            },
        });
    }
}

export const register = (resp) => {
    return async dispatch => {
        const response = await Api.post('auth/register', resp);
        if( response.success ){
            const { accessToken, user } = response.data;
            setSession(accessToken,user);
            Api.setToken(accessToken);
            dispatch({
                type: 'LOGIN',
                payload: {
                    user,
                    accessToken
                },
            });
        }
        return response;
    }
}

export const logout = () => {
    return async dispatch => {
        Api.get('auth/logout').then(res=>{ });
        setSession(null);
        dispatch({ type:'LOGOUT' });
    }
}

export const setFBToken = (token) => {
    return async dispatch => dispatch({ type: 'SET_FIREBASE_TOKEN', payload:token });
}

export const setGeoData = (data) => {
    return async dispatch => dispatch({ type: 'SET_GEO_DATA', payload:data });
}