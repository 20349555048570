import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Dashboard = Loadable(lazy(() => import('./Dashboard')));

const EditProfile = Loadable(lazy(() => import('./EditProfile')));
const ChangePassword = Loadable(lazy(() => import('./ChangePassword')));
const Messages = Loadable(lazy(() => import('./Messages')));
const Friends = Loadable(lazy(() => import('./Friends')));
const Visitors = Loadable(lazy(() => import('./Visitors')));
const FriendRequests = Loadable(lazy(() => import('./FriendRequests')));
const Notifications = Loadable(lazy(() => import('./Notifications')));
const Settings = Loadable(lazy(() => import('./Settings')));
const Users = Loadable(lazy(() => import('./Users')));
const Places = Loadable(lazy(() => import('./Places')));
const NotFound = Loadable(lazy(() => import('./NotFound')));

const routes = [
    { path: '/dashboard', element: <Dashboard />, auth: authRoles.user },
     
    { path: '/profile/edit/:tab', element: <EditProfile />, auth: authRoles.user },
    { path: '/profile/edit', element: <EditProfile />, auth: authRoles.user },
    { path: '/profile/changepassword', element: <ChangePassword />, auth: authRoles.user },
    
    { path: '/dashboard/messages/:username', element: <Messages />, auth:authRoles.user },
    { path: '/dashboard/messages', element: <Messages />, auth: authRoles.user },
    { path: '/dashboard/friends', element: <Friends />, auth: authRoles.user },
    { path: '/dashboard/friend-requests', element: <FriendRequests />, auth: authRoles.user },
    { path: '/dashboard/visitors', element: <Visitors />, auth: authRoles.user },
    { path: '/dashboard/notifications', element: <Notifications />, auth: authRoles.user },
    { path: '/dashboard/settings/:tab',element:<Settings />,auth: authRoles.user },
    { path: '/dashboard/settings', element: <Settings />, auth: authRoles.user },

    { path: '/users/search/:type/:value', element:<Users />,auth: authRoles.guest },
    { path: '/users/search/:type', element: <Users />, auth: authRoles.guest },
    { path: '/users/search', element: <Users />, auth: authRoles.guest },
    { path: '/users', element: <Users />, auth: authRoles.guest },
    
    { path: '/places', element: <Places />, auth: authRoles.guest },    
    { path: '/dashboard/*', element: <NotFound /> },
    { path: '/profile/*', element: <NotFound /> }    
];

export default routes;