import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import CounterReducer from './CounterReducer';
import SettingReducer from './SettingReducer';
import ToastReducer from './ToastReducer';
//import EcommerceReducer from './EcommerceReducer';
//import NavigationReducer from './NavigationReducer';
//import NotificationReducer from './NotificationReducer';

const RootReducer = combineReducers({
  auth: AuthReducer,
  counter: CounterReducer,
  settings: SettingReducer,
  //notifications: NotificationReducer,
  //navigations: NavigationReducer,
  //ecommerce: EcommerceReducer,
  toast: ToastReducer,
});

export default RootReducer;
