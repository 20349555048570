import { Fragment, useEffect } from 'react';
import { Suspense } from 'app/components';
import { Layouts } from './index';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import pageMeta from '../../../config/pageMeta';
import config from '../../../config/config.json';
import ReactGA from "react-ga4";

const Layout = (props) => {
  const type = props.type || "default";
  const LayoutM = Layouts[type] || Layouts[type].default;
  const location = useLocation();
  const defaultTitle = config?.title;
  
  useEffect(()=>{
    if( config?.ga?.measurementId )
      ReactGA.initialize(config?.ga?.measurementId);
  },[]);  
  
  useEffect(()=>{
    if( config?.ga?.measurementId ){
      ReactGA.send({ 
        hitType: "pageview", 
        page: `/${location?.pathname}`, 
        title: getTitle(location?.pathname)
      });
    }
  },[location?.pathname]);

  const getTitle= (path, dflt = null) =>{
      var title = '';
      if( path === '' || path === '/')
        return dflt || '';

      if( pageMeta && typeof pageMeta === 'object' ){
        let _path = (`${path || ''}/`).replace('//','/');
        Object.entries(pageMeta).map(([pk,pd])=>{
          if( pk.includes('/*') ){
            pk = pk.replace('*','');
            if( (`#${_path}`).includes(`#${pk}`) )
              title = pd?.title || '';
          } else {
            if( pk === _path ){
              title = pd?.title || '';
            }
          }
        });
        if( title === '' && pageMeta[path])
          title = pageMeta[path]?.title || '';
      }

      if( dflt )
        title = title !== '' ? `${title} - ${dflt}` : dflt;

      return title;
  }

  return (
    <Fragment>
      {defaultTitle && <Helmet>
        <title>{getTitle(location?.pathname,defaultTitle)}</title>
      </Helmet>}
      <Suspense>
        <LayoutM {...props} />
      </Suspense>
    </Fragment>
  );
};

export default Layout;
