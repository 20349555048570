import React  from 'react';
import { styled , AlertTitle } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import { useWindowSize } from "app/hooks/useWindowSize";
import { topBarHeight } from 'app/utils/constant';

const AlertContainer = styled(MuiAlert)(({ mt, mb, bg, spc, bc, tc, variant }) => ({
    margin:spc ? `${spc}px` : `${mt}px 0 ${mb}px 0`, backgroundColor: bg, borderColor:bc,
    '& .MuiAlert-icon': { color: variant === "outlined" ? tc : "#FFF", marginRight:"6px", },
}));

const Message = styled('div')(({ color }) => ({ color }));

const Alert = React.forwardRef((props, ref) =>{
    
    let _props = {}, showTitle = 0, 
      title=props?.title || null, 
      message = props?.message || (props?.children || null), 
      margin = props?.margin || false, 
      variant = props?.variant || "filled", 
      severity = (props?.severity || "info").trim(),
      spacing = props?.spacing || null;

    const wSize = useWindowSize();
    let mt = margin ? (wSize.width <= 600 ? topBarHeight : topBarHeight-16) : 0;
    if( !message ) return null;
    if( !title) title = severity;
    if( props?.showTitle ) showTitle = 1;

    let bgColors = {
      success: "#e8f5e9",
      error: '#f2dede',//"rgb(255, 99, 120)",
      warning:"#fff3e0",
      info: "#d9edf7",
    }

    let bdColors = {
      success: "#d8edd6",
      error: '#eed3d7',//"rgb(255, 99, 120)",
      warning:"#ffe5d1",
      info: "#bce8f1",
    }

    let Colors = {
      success: "#409945",
      error: '#b94a48',//"rgb(255, 99, 120)",
      warning:"#ef6c00",
      info: "#409945",
    }

    Object.entries(props || {}).map(([pk,pv])=>{
        if( !['showTitle','title','message','margin'].includes(pk) )
            _props[pk] = pv;
        return pv;
    });

    return <AlertContainer {..._props} className={`alert ${showTitle ? 'st': ''}`} ref={ref} mt={mt} mb={props?.mb === 0 ? 0 : (props?.mb || 10)} spc={spacing} bg={variant === "outlined" ? (bgColors[severity] || "#222222") : null} st={showTitle} severity={severity} bc={bdColors[severity] || "#222222"} variant={variant} tc={variant === "outlined" ? (Colors[severity] || "") : ''}>
        {showTitle > 0 && <AlertTitle>{title}</AlertTitle>}
        <Message className="message" color={variant === "outlined" ? (Colors[severity] || "") : ''}>{message}!</Message>
    </AlertContainer>
});

export default Alert;
