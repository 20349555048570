
export const getGeoLocation = (setData, opt = {}) => {

    var options = {
      //enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };   

    if( opt )
        options = {...options, ...opt};

    if( "geolocation" in navigator && navigator.geolocation) {
        navigator.permissions.query({ name: "geolocation" }).then(function (result) {
            if (result.state === "granted" || result.state === "prompt") {
                navigator.geolocation.getCurrentPosition(pos=>{
                    if( pos.coords && pos.coords?.latitude )
                        setData({ latitude: pos.coords.latitude, longitude: pos.coords.longitude });
                }, (e)=>{ }, options);
            }
        });
    }
}