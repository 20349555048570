import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Home = Loadable(lazy(() => import('./Home')));
const Login = Loadable(lazy(() => import('./Login')));
const Register = Loadable(lazy(() => import('./Register')));
const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')));
const Content = Loadable(lazy(() => import('./Content')));
const Blogs = Loadable(lazy(() => import('./Blogs')));
const News = Loadable(lazy(() => import('./News')));
const NotFound = Loadable(lazy(() => import('./NotFound')));

const sessionRoutes = [
  { path: '/', element: <Home /> },
  { path: '/login', element: <Login /> },
  { path: '/register', element: <Register /> },
  { path: '/forgotpassword', element: <ForgotPassword /> },
  { path: '/about.html', element: <Content type="about" /> },
  { path: '/privacy-policy.html', element: <Content type="privacy-policy" /> },
  { path: '/terms-conditions.html', element:  <Content type="terms-conditions" /> },
  { path: '/contact-us.html', element:  <Content type="contact-us" />},
  { path: '/blogs/:category/:slug', element: <Blogs type="view" /> },
  { path: '/blogs/:category', element: <Blogs type="category" /> },
  { path: '/blogs', element: <Blogs type="list" /> },
  { path: '/news/:country/:slug', element: <News type="view" /> },
  { path: '/news/:country', element: <News type="list" /> },
  { path: '/news', element: <News type="list" /> },
  { path: '/404', element: <NotFound /> },
];

export default sessionRoutes;
