import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { flat } from 'app/utils/utils';
import { Navigate, useLocation } from 'react-router-dom';
import { Loading } from 'app/components';

import Timer from 'app/components/Timer';
import CounterActions from 'app/redux/actions/CounterActions';
import interval from '../../config/interval';

const AuthGuard = ({ children }) => {

  const auth = useSelector((store) => store.auth);
  const counter = useSelector((store) => store.counter);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(()=>getCounters(1000),[auth?.isLogged]);
  
  const getCounters = (t) => {
    if( auth?.isLogged )
      setTimeout(()=>dispatch(CounterActions()),t);  
  }

  const onInterval = (s) =>{
    if(counter?.running){
      getCounters(0);
    }
  }
  
  //   const routes = flat(AllPages);
  //   const hasPermission = userHasPermission(pathname, user, routes);
  //   let authenticated = isAuthenticated && hasPermission;

  // // IF YOU NEED ROLE BASED AUTHENTICATION,
  // // UNCOMMENT ABOVE LINES
  // // AND COMMENT OUT BELOW authenticated VARIABLE
  //let authenticated = ;
 // let authenticated = isAuthenticated;

 
  if( !auth.isInitialised )
    return <Loading/>;

  return (
    <>
      {auth?.isLogged ? (<>
        {children}
        <Timer time={interval?.counter || 6} onInterval={onInterval}/>
      </>) : (
        <Navigate replace to="/login" state={{ from: pathname }} />
      )}
    </>
  );
};

export default AuthGuard;