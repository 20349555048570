import { LayoutSettings } from 'app/components/Layout/settings';

const SettingReducer = function (state = LayoutSettings, action) {
  
    switch (action.type) {
        case 'INIT': {
            return {...state, ...action.payload};
        }

        case 'SET_MODE': {

            let activeLayoutSettingsName = state.activeLayout + 'Settings';
            let activeLayoutSettings = state[activeLayoutSettingsName];

            return {
              ...state,
              [activeLayoutSettingsName]: {
                ...activeLayoutSettings,
                leftSidebar: {
                  ...activeLayoutSettings.leftSidebar,
                  ...{ mode: action.payload},
                },
              },
            };
        }

        case 'UPDATE_SETTINGS': {
            return {
              ...state,
              ...action.payload
            };
        }

        default: {
            return { ...state }
        }
    }
};

export default SettingReducer;