import { Fragment } from 'react';
import { Snackbar } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import * as ToastActions from 'app/redux/actions/ToastActions';
import Alert from 'app/components/Alert';

const ToastContainer = ({ vertical="top", horizontal="right" }) => {

    const toasts = useSelector((store) => store.toast);
    const dispatch = useDispatch();
    const handleClose = () => dispatch(ToastActions.remove('1'));

    return (
        <Fragment>
            {Object.entries(toasts?.items || {}).map(([tk,tdata])=>{
                let props = tdata?.params || {}, type = tdata?.type || "info", message = (tdata?.message || '').trim(), duration = 20000;
                if( props?.duration ){ duration = parseInt(props?.duration)*1000; delete props?.duration; }
                if( props?.vertical ){ vertical = props?.vertical; delete props?.vertical; }
                if( props?.horizontal ){ horizontal = props?.horizontal; delete props?.horizontal; }
                if( message ===   '' ) return null;

                return <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={toasts?.open || false}
                    autoHideDuration={duration}
                    onClose={handleClose}
                    key={`${vertical}_${horizontal}_${tk}`}
                >
                    <Alert severity={type} variant="outlined" onClose={handleClose} margin={true} message={message} {...props}/>
                </Snackbar>
            })}
        </Fragment>
    );
};

export default ToastContainer;
