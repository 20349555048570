import { useEffect, useState } from 'react';

export  function useWindowSize() {
    
    const getSize = () => { return { 
        width: window?.innerWidth || 0, 
        height: window?.innerHeight || 0,
        ratio: (window?.innerWidth || 0) / ( window?.innerHeight || 1),
    }; };

    const [size,setSize] = useState(getSize());

    useEffect(() => {
        getSize();
        window.addEventListener("resize", ()=>setSize(getSize()));
        return () => window.removeEventListener("resize", ()=>setSize(getSize()));
    }, []);
    
    return size;
}